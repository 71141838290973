import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Trans = ({ order }) => {
	return (
		<Card className='my-3 p-3 rounded'>
			{/* <Link to={`/product/${order._id}`}>
        <Card.Img src={order.image} variant="top" />
      </Link> */}

			<Card.Body>
				<Card.Title as='div'>
					<strong>DigiStore Order - {order.receipt}</strong>
				</Card.Title>
				<Card.Text as='h6'>
					<strong>NAME:</strong> {order.firstName} {order.lastName}
				</Card.Text>
				{/* <Card.Text as="h6">Receipt: {order.receipt}</Card.Text> */}
				<Card.Text as='h6'>
					<strong>EMAIL:</strong> {order.email}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>PHONE:</strong>{' '}
					{order.phoneNumber !== '' ? order.phoneNumber.replace(/\+/g, '') : 'n/a'}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>PRODUCT:</strong> {order.productTitle}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>STATUS:</strong> {order.transactionType}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>LOCATION:</strong> {order.state} / {order.postalCode}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default Trans;
