import { useState } from 'react';
import { Row, Col, Spinner, Container, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const OrderSearch = () => {
  const [loading, setIsLoading] = useState(false);
  const [nickName, setNickName] = useState('');
  const [nickStatus, setNickStatus] = useState('');
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { data } = await axios.get('/api/verifyNick/', {
      params: { nickName: nickName, vendorNickname: 'precmedia' },
    });
    console.log(data);
    if (data.data.encodeHopShieldLink !== null) {
      setNickStatus('Nickname is Valid');
      setVariant('success');
      setShow(true);
    } else {
      console.log('Nick Errors', data.errors[0].message);
      setNickStatus(data.errors[0].message);
      setVariant('danger');
      setShow(true);
    }
    console.log('Nick Status: ', nickStatus);
    setIsLoading(false);
  };

  if (!loading) {
    return (
      <>
        <h5>VERIFY NICKNAME</h5>
        <Link className="btn btn-light my-3" to="/">
          Go Back
        </Link>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nickname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Nickname"
                  value={nickName}
                  onChange={(e) => setNickName(e.target.value)}
                  onFocus={(e) => setShow(false)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button variant="primary" type="submit">
                Verify Nickname
              </Button>
            </Col>
          </Row>
        </Form>
        <Row style={{ paddingTop: '20px' }}>
          {show === true && (
            <Col lg={9}>
              <Alert variant={variant} onClose={() => setShow(false)} dismissible>
                <p>{nickStatus}</p>
              </Alert>
            </Col>
          )}
        </Row>
      </>
    );
  } else {
    return (
      <Row>
        <Container style={{ alignItems: 'center', textAlign: 'center' }}>
          <Spinner animation="grow" variant="secondary" size="lrg" />
        </Container>
      </Row>
    );
  }
};
export default OrderSearch;
