import { useState } from 'react';
import { Row, Col, Spinner, Container, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SendGridScreen = () => {
  const [email, setEmail] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Email:', email);

    // This is the axios call to SendGrid API via server.js
    const { data } = await axios.get('/api/sendGrid/', {
      params: { email: email },
    });

    console.log(data);

    if (data !== null) {
      setVariant('success');
      setShow(true);
    } else {
      //setEmail(data.errors[0].message);
      setVariant('danger');
      setShow(true);
    }

    setIsLoading(false);
  };

  if (!loading) {
    return (
      <>
        <h5>Email Supression</h5>
        <Link className="btn btn-light my-3" to="/">
          Go Back
        </Link>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email To Suppress"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => setShow(false)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button variant="primary" type="submit">
                Suppress Email
              </Button>
            </Col>
          </Row>
        </Form>
        <Row style={{ paddingTop: '20px' }}>
          {show === true && (
            <Col lg={6}>
              <Alert variant={variant} onClose={() => setShow(false)} dismissible>
                <p>Email Added To Global Suppression List!</p>
              </Alert>
            </Col>
          )}
        </Row>
      </>
    );
  } else {
    return (
      <Row>
        <Container style={{ alignItems: 'center', textAlign: 'center' }}>
          <Spinner animation="grow" variant="secondary" size="lrg" />
        </Container>
      </Row>
    );
  }
};
export default SendGridScreen;
