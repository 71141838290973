import { Card } from 'react-bootstrap';

const Trans = ({ cbOrder }) => {
	return (
		<Card className='my-3 p-3 rounded'>
			<Card.Body>
				<Card.Title as='div'>
					<strong>Clickbank Order - {cbOrder.receipt}</strong>
				</Card.Title>
				<Card.Text as='h5'>
					{cbOrder.firstName} {cbOrder.lastName}
				</Card.Text>
				{/* </Card.Body></Card.Text as="h6">Receipt: {order.receipt}</> */}
				<Card.Text as='h6'>
					<strong>EMAIL:</strong> {cbOrder.email}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>PHONE:</strong>{' '}
					{cbOrder.phoneNumber !== ''
						? cbOrder.phoneNumber.replace(/\+/g, '')
						: 'N/A'}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>STATUS:</strong> {cbOrder.transactionType}
				</Card.Text>
				<Card.Text as='h6'>
					<strong>LOCATION:</strong> {cbOrder.state} / {cbOrder.postalCode}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default Trans;
