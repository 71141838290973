import { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const HomeScreen = () => {
  const [lastName, setLastName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/search', { state: { lastName: lastName } });
  };

  return (
    <>
      <h5>TRANSACTIONAL SEARCH</h5>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={6}>
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Form.Group> */}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            {/* <Button variant='primary' type='submit'>
							Submit
						</Button> */}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default HomeScreen;
