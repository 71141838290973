import { Navbar, Nav, Container, NavbarBrand } from 'react-bootstrap';
//import { FaEnvelope, FaHome } from 'react-icons/fa';
import {
	GiArtificialIntelligence,
	GiPoisonBottle,
	GiBinoculars,
	GiMonkey,
} from 'react-icons/gi';
import { LinkContainer } from 'react-router-bootstrap';

const Header = () => {
	return (
		<header>
			<Navbar bg='dark' variant='dark' expand='md' collapseOnSelect>
				<Container>
					<LinkContainer to='/'>
						<NavbarBrand>
							<GiArtificialIntelligence size={42} /> <b>PRECISON MEDIA LABS </b>
						</NavbarBrand>
					</LinkContainer>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							<LinkContainer to='/'>
								<Nav.Link>
									<GiBinoculars size={30} /> <b>Customer Search</b>
								</Nav.Link>
							</LinkContainer>
							<LinkContainer to='/sendGrid'>
								<Nav.Link>
									<GiPoisonBottle size={30} />
									<b>Suppress Email</b>
								</Nav.Link>
							</LinkContainer>
							<LinkContainer to='/verifyNick'>
								<Nav.Link>
									<GiMonkey size={30} /> <b>Verify Nickname</b>
								</Nav.Link>
							</LinkContainer>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
