import { useEffect, useState } from 'react'
import {Row, Col, Spinner, Container} from 'react-bootstrap'
import DigiTransactions from '../components/DigiTransactions'
import CBTransactions from '../components/CBTransactions'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'


const OrderSearch = () => {
  const [digiCount , setDigiCount] = useState(0);
  const [loading, setIsLoading] = useState(true);
  const [cbCount , setCB_Count] = useState(0);
  const [totalCount , setTotalCount] = useState(0);

  const [orders, setOrders] = useState([]);
  const [cbOrders, setCB_Orders] = useState([]);
  const location = useLocation();
  const { lastName } = location.state || {};


  useEffect( () => {
    const fetchDigiOrders  = async () => {
        const { data } = await axios.get('/api/digiSearch/', {params: {"lastName": lastName}});
        setOrders(data);
        setDigiCount(data.length);
      };

      const fetchCB_Orders  = async () => {
        const { data } = await axios.get('/api/cbSearch/', {params: {"lastName": lastName}});
        setCB_Orders(data);
        setCB_Count(data.length);
        console.log('CB Orders: ', data);
      };

      const tallyCount = () => {
        const sum = parseInt(digiCount) + parseInt(cbCount);
         setTotalCount(sum);
       }

      // setTimeout(() => {
      //   fetchDigiOrders
      // }, 3000);

      fetchDigiOrders();
      fetchCB_Orders();
      tallyCount();
      setIsLoading(false);
      

    }, []);

if (!loading) {    
return (
    <>
    <h5>Transactions Search Results - {totalCount}</h5>
    <Link className='btn btn-light my-3' to='/'>Go Back</Link>
    <Row>
    
      {orders.results !== '0' &&
       orders.map((mapKey)=> (
              <Col key={mapKey.receipt} sm={12} md={6} lg={4} xl={4}>
                  <DigiTransactions order={mapKey} />
              </Col>
          ))
      }
    </Row>
    <Row>
    
      {cbOrders.results !== '0' &&
       cbOrders.map((mapKey)=> (
              <Col key={mapKey.id} sm={12} md={6} lg={4} xl={4}>
                  <CBTransactions cbOrder={mapKey} />
              </Col>
          ))
      }
    </Row>
    </>
  )
    }
    else {
      return (
        <Row>
          <Container style={{alignItems: 'center', textAlign: 'center'}}>
            <Spinner animation="grow" variant='secondary' size='lrg'/>
          </Container>
        </Row>
      )
    }
  
   
}
export default OrderSearch